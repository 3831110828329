<template>
  <section class="section section bg-gray">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-6">
          <div class="feature-detail">
            <div class="title-box text-center">
              <h2 class="fadeIn animated wow" data-wow-delay=".1s">{{$t("Reports")}}</h2>
              <div class="border"></div>
            </div>

            <p>{{$t("A report will be delivered monthly where you can see the possible incidents of the service, the performance of the dashboard (Manager), the performance of the scripst, among other important data.")}}</p>
          </div>
        </div>
        <div class="col-6">
          <img
            src="../../assets/images/manage/reports.png"
            class="img-fluid fadeIn animated wow center-block"
            data-wow-delay=".2s"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Reports"
};
</script>

<style scoped>
.bg-gray {
  background-color: #ededed !important;
}
.feature-detail .border {
  margin-left: auto !important;
}
</style>
